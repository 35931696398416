import axios, { AxiosError } from "axios";
import { OlliePipeEvent, OlliePipeMeta } from "@ollie-sports/models";

interface Config {
  disabled?: boolean;
  batchDelay?: number;
  getAccountId: () => Promise<string>;
  asyncStorage?: {
    write: (events: any[]) => Promise<void>;
    read: () => Promise<any[]>;
    clear: () => Promise<void>;
  };
  os?: string;
  deviceId?: string;
  enableVerboseErrorLogging: boolean;
  source: OlliePipeMeta["source"];
  pipeUrl: string;
  pipeFallBackUrlFirestore: string; // Example "https://firestore.googleapis.com/v1/projects/ollie-admin-prod/databases/(default)/documents/backupPipe"
  session_id: string;
  git: string;
}

export class OlliePipe {
  private pendingEvents: any[] = [];
  private batchTimeout: any;

  constructor(private config: Config) {
    if (!config.batchDelay) {
      // By default we delay the sending so we can batch things together
      config.batchDelay = 3000;
    }
  }

  public async emitEvent(p: OlliePipeEvent | string, options?: { sendImmediate?: boolean; disableDevTerminalLogging?: true }) {
    if (this.config.disabled) {
      return;
    }
    clearTimeout(this.batchTimeout);

    let pendingEvent: OlliePipeEvent;
    if (typeof p === "string") {
      pendingEvent = { type: p, payload: {} };
    } else {
      pendingEvent = { type: p.type, payload: p.payload instanceof Error ? errorToObject(p.payload) : p.payload };
    }

    if (this.config.enableVerboseErrorLogging && !options?.disableDevTerminalLogging && pendingEvent.type.match("error-")) {
      try {
        let valToLog: any;

        if (typeof p !== "string" && p.payload instanceof Error) {
          if ((p.payload as any).isAxiosError) {
            const err: AxiosError = p.payload as any;
            valToLog = new Error(err.message);
            valToLog.stack = err.stack;
            valToLog.request = {
              requestUrl: err.config.url,
              requestData: err.config.data
            };
            valToLog.response = {
              responseStatus: err.response.status,
              responseText: err.response.statusText,
              responseData: err.response.data ? JSON.stringify(err.response.data, null, 2) : undefined
            };
          } else {
            valToLog = p.payload;
          }
        } else {
          valToLog = JSON.stringify(pendingEvent, null, 2).slice(0, 1500);
        }

        console.error(valToLog);
      } catch (e) {
        console.info(pendingEvent);
      }
    }

    this.pendingEvents.push(pendingEvent);

    if (options && options.sendImmediate) {
      await this.flushPendingEvents();
    } else {
      this.batchTimeout = setTimeout(() => this.flushPendingEvents(), this.config.batchDelay);
    }
  }

  private async getMeta(): Promise<OlliePipeMeta> {
    return {
      account_id: await this.config.getAccountId(),
      session_id: this.config.session_id,
      source: this.config.source,
      os: this.config.os || "UNKNOWN",
      deviceId: this.config.deviceId || "UNKNOWN",
      date_ms: Date.now(),
      git: this.config.git || "UNKNOWN"
    };
  }

  public async flushPendingEvents() {
    if (this.config.disabled) {
      return;
    }
    // Clear any pending timeouts
    clearTimeout(this.batchTimeout);
    let eventsToSend: OlliePipeEvent[] = [...this.pendingEvents];
    this.pendingEvents = [];
    const meta = await this.getMeta();

    if (this.config.asyncStorage) {
      try {
        const otherPendingEvents = await this.config.asyncStorage.read();
        if (otherPendingEvents.length > 0) {
          eventsToSend = [...eventsToSend, ...otherPendingEvents];
          await this.config.asyncStorage.clear();
        }
      } catch (e) {
        console.error(`Trouble running asyncStorage in ollie-pipe. Msg: ${e.msg}`);
      }
    }

    try {
      await axios
        .post(this.config.pipeUrl, {
          meta,
          events: eventsToSend
        })
        .catch(async () => {
          await new Promise(res => setTimeout(res, 1000));
          return await axios.post(this.config.pipeUrl, {
            meta,
            events: eventsToSend
          });
        });
    } catch (err) {
      // Trouble posting to main pipe so we will write to async storage and try again on the next event
      this.pendingEvents = [...eventsToSend, ...this.pendingEvents];
      if (this.config.asyncStorage) {
        try {
          let eventsToStore = this.pendingEvents;
          this.pendingEvents = [];
          await this.config.asyncStorage.write(eventsToStore);
        } catch (err3) {
          console.error(`Trouble running asyncStorage in ollie-pipe. Msg: ${err3.msg}`);
        }
      }
    }
  }
}

function errorToObject(a: Error) {
  const obj: any = {};
  Object.getOwnPropertyNames(a).forEach(k => {
    const val = (a as any)[k];
    if (val) {
      obj[k] = val;
    }
  });

  return obj;
}
